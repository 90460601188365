.blackTooltip {
    background-color: transparent !important; 
    margin: 0 !important;
    padding: 0 !important;
    border: none !important;
  }
  
  .blackTooltip.leaflet-tooltip::before,
  .blackTooltip.leaflet-tooltip::after {
    border: none !important;
  }
